<template>
  <div class="service">
    <div class="team-btn mybutton" @click="toRouter('/about')">
      <img :src="require('/src/assets/img/right-arrow.png')" /><span
        >Our Company</span
      >
    </div>
    <div class="detail">
      <div class="text panel">
        MSc in Software Engineering, University of Wales, UK. 2014 was appointed
        Vice President International Business of ST Engineering. Participated in
        the Singapore MoD, Police Force, Fire Service C3 System Development and
        Implementation. Focus on Rail Electronic Systems, Urban Transportation,
        Smart Buildings and Public Safety systems.
      </div>
      <div class="photo">
        <div><img :src="require('/src/assets/img/about-tream.png')" /></div>
        <div class="panel">
          <div>Jeremy Goh</div>
          <div>CEO, Singapore</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/about_us.png") center center;
  background-size: cover;
  font-size: 3.34vh;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 4.63vh;
  letter-spacing: 0.117vw;

  .team-btn {
    margin: 0px 2vw;
    font-size: 4.17vh;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 5.83vh;
    letter-spacing: 0.146vw;
    text-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    display: flex;
    align-items: center;
    > img {
      height: 5.4vh;
      transform: rotateZ(180deg);
    }
  }
  .panel {
    background: #00000065;
    padding: 0px 25px;
  }
  .detail {
    display: flex;
    align-items: center;
    padding: 0px 2.97vw 0px 4.06vw;
    .text {
      width: 64.58vw;
      margin-right: 2.97vw;
      padding: 3vh 1.5vw;
    }
    .photo {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 51.94vh;
      }
      .panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1vh 4.43vw;
      }
    }
  }
}
</style>
